
const announcement={
    height:'30px',
    backgroundColor:'teal',
    color:'white',
    display:'flex',
    alignItems:'center',
    justifyContent:'center',
    letterSpacing:'3px'

}
const Announcement=()=>{
    return (
        <div style={announcement}>The offer is of 50% for only Today</div>
    )
}

export default Announcement;